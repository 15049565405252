import React from 'react';

import { Grid } from '@core';
import { ImageCore, RatingStars, Text } from '@components';

const ReviewCard = ({ logoImage, author, comment, rating }) => (
  <Grid.Container py={32} px={24}>
    <Grid.Row>
      <Grid.Col mb={8} display="flex">
        <ImageCore src={logoImage.src} alt={logoImage.alt} height={21} />
      </Grid.Col>
      <Grid.Col mb={16}>
        <Text.Body1Strong textAlign="left">{author}</Text.Body1Strong>
      </Grid.Col>
      <Grid.Col mb={16} display="flex">
        <RatingStars rating={rating} fillColor="#E8D07D" />
      </Grid.Col>
      <Grid.Col>
        <Text.Body2 textAlign="left">{comment}</Text.Body2>
      </Grid.Col>
    </Grid.Row>
  </Grid.Container>
);

export default ReviewCard;
